import { hookActivated, extractHookId, removeObserver } from 'OK/HookModel';

var promise;

function activate(element) {
    var loaderId = element.getAttribute('data-loader-id'),
        hookId = extractHookId(loaderId);

    promise = hookActivated(hookId);
    promise.then(loadMore);

    function loadMore() {
        // Асинхронно выполним, чтобы не мешать активации
        setTimeout(function() {
            var event = document.createEvent('MouseEvents'),
                showMoreButton = element.parentNode.getElementsByClassName('js-show-more')[0];
            event.initEvent("click", false, true);
            showMoreButton.dispatchEvent(event);
        });
    }
}

function deactivate(element) {
    var loaderId = element.getAttribute('data-loader-id'),
        hookId = extractHookId(loaderId);

    if (promise) {
        removeObserver(promise, hookId);
    }
}

export default { activate, deactivate };

export { activate, deactivate };
